.contact-form {
  display: flex;
  height: 80vh;
  padding-bottom: 4rem;
  margin-bottom: 2rem;
  gap: 2rem;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10rem;
}
.contact-form .w-left {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  align-items: flex-start;
}
.contact-form .w-left .desc {
  width: 25rem;
  color: var(--gray2);
}
.contact-form .w-left .awesome {
  display: flex;
  flex-direction: column;
}
.contact-form .awesome > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.contact-form .awesome > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}

.contact-form .w-left > form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.contact-form .w-left .user {
  width: 25rem;
  height: 2rem;
  padding: 0.3em;
  outline: none;
  border: 2px solid var(--orange);
  border-radius: 8px;
  font-size: 16px;
}

textarea {
  height: 5rem !important;
}

.c-right {
  display: flex;
  justify-content: center;
  position: relative;
}

.c-right img {
  width: 35rem;
  height: 35rem;
}
.c-right .map-wrapper {
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
}
.c-right img:hover {
  transform: scale(1.2);
}
.c-right .map-marker {
  position: absolute;
  right: 40%;
  top: 40%;
  width: 4rem;
  height: 4rem;
}
.c-right .Address {
  position: absolute;
  top: 1rem;
  left: 2rem;
  background: rgb(0, 0, 0, 1);
  color: white;
  width: fit-content;
  height: fit-content;
  padding: 1rem;
}
.c-right .Address span {
  font-size: larger;
  font-weight: 600;
}
.c-right .Address p {
  font-size: small;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
}
.c-right .Address .cntct {
  display: flex;
  flex-direction: row;
  font-size: small;
  font-weight: 400;
}

.c-blur1 {
  top: 1rem;
  left: 8rem;
}

@media screen and (max-width: 800px) {
  .contact-form {
    padding: 0;
    flex-direction: column;
    gap: 2rem;
    height: 120vh;
    align-items: center;
    margin-bottom: 10rem;
  }
  .contact-form .w-left .user {
    width: 33rem;
  }
  .contact-form .w-left {
    margin-bottom: 0rem;
    justify-content: center;
    align-items: center;
    margin-left: 0rem;
    text-align: center;
  }
  .contact-form .w-left .desc {
    font-size: 20px;
    width: 30rem;
  }
  .contact-form .w-left .awesome span {
    font-size: 2.5rem;
    text-align: center;
  }

  .c-right img {
    width: 35rem;
    height: 35rem;
  }
  .c-right .map-marker {
    width: 4rem;
    height: 4rem;
  }
}

@media screen and (max-width: 680px) {
  .contact-form {
    padding: 0;
    flex-direction: column;
    gap: 2rem;
    height: 120vh;
    align-items: center;
    margin-bottom: 15rem;
  }
  .contact-form .w-left .user {
    width: 23rem;
  }
  .contact-form .w-left {
    margin-bottom: 0rem;
    justify-content: center;
    align-items: center;
    margin-left: 0rem;
    text-align: center;
  }
  .contact-form .w-left .desc {
    font-size: 16px;
    width: 25rem;
  }
  .contact-form .w-left .awesome span {
    font-size: 2rem;
    text-align: center;
  }

  .c-right img {
    width: 25rem;
    height: 25rem;
  }
  .c-right .map-marker {
    width: 3rem;
    height: 3rem;
  }
}

@media screen and (max-width: 480px) {
  .contact-form {
    padding: 0;
    flex-direction: column;
    gap: 2rem;
    height: 100vh;
    align-items: center;
    margin-bottom: 4rem;
  }
  .contact-form .w-left .user {
    width: 18rem;
  }
  .contact-form .w-left {
    margin-bottom: 0rem;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .contact-form .w-left .desc {
    font-size: 14px;
    width: 20rem;
  }
  .contact-form .w-left .awesome span {
    font-size: 2rem;
    text-align: center;
  }

  .c-right img {
    width: 20rem;
    height: 20rem;
  }
  .c-right .map-marker {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media screen and (max-width: 360px) {
  .contact-form {
    margin-top: 4rem;
    height: auto;
    margin-bottom: 2rem;
  }
  .contact-form .w-left .user {
    width: 16rem;
  }

  .contact-form .c-right img {
    width: 17rem;
    height: 17rem;
  }
  .contact-form .c-right .map-marker {
    width: 2.3rem;
    height: 2.3rem;
  }
  .c-right .Address {
    position: absolute;
    top: 1rem;
    left: 1rem;

    background: rgb(0, 0, 0, 1);
    color: white;
    width: fit-content;
    height: fit-content;
    padding: 0.5rem;
  }
  .c-right .Address span {
    font-size: large;
    font-weight: 600;
  }
  .c-right .Address p {
    font-size: smaller;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .c-right .Address .cntct {
    display: flex;
    flex-direction: row;
    font-size: smaller;
    font-weight: 400;
  }
}
