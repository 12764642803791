.n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0rem;
  top: 0;
}
.n-left {
  flex: 1;
  align-items: center;
  justify-content: left;
  display: flex;
  gap: 2rem;
}

.n-name {
  font-size: 1.3rem;
  font-weight: bold;
}
.n-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
}

.n-list {
  flex: 10;
}
.n-list > ul {
  display: flex;
  gap: 2rem;
  margin-right: 4rem;
}

.n-list > ul :hover {
  color: var(--orange);
  cursor: pointer;
}

.n-button {
  margin-top: 5px;
}
.n-wrapper img {
  display: none;
}
.n-close {
  position: absolute;
  top: 25px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}
@media screen and (max-width: 480px) {
  .n-list {
    position: absolute;
    top: 0;
    right: 0;
    height: 40vh;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background-color: var(--orange);
    transition: 1s;
    transform: translateX(50vh);
    z-index: 2;
  }
  .n-close {
    visibility: inherit;
    opacity: 1;
  }
  .n-list > ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-decoration: sandybrown;
  }
  .responsive_navi {
    transform: none;
  }
  .n-wrapper img {
    display: inline;
    height: 22px;
    width: 25px;
    margin-top: -5px;
    z-index: 3;
  }
  .n-wrapper .n-button {
    display: none;
  }
  .n-wrapper {
    height: 7vh;
  }
}

@media screen and (max-width: 680px) {
  .n-list {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 30vh;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background-color: var(--orange);
    transition: 1s;
    transform: translateX(50vh);
    z-index: 2;
  }
  .n-close {
    visibility: inherit;
    opacity: 1;
  }
  .n-list > ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-decoration: sandybrown;
  }
  .responsive_navi {
    transform: none;
  }
  .n-wrapper img {
    display: inline;
    height: 22px;
    width: 25px;
    margin-top: -5px;
    z-index: 3;
  }
  .n-wrapper .n-button {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .n-list > ul {
    display: flex;
    gap: 1rem;
    margin-right: 2rem;
  }
  .n-left {
    flex: 1;
    align-items: center;
    justify-content: left;
    display: flex;
    gap: 1rem;
  }

  .n-name {
    font-size: 1rem;
    font-weight: bold;
  }
}
