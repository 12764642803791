.footer {
  display: flex;
  align-items: center;
  margin: -0.5rem -3.5rem;
  position: relative;
  margin-top: -7rem;
}
.f-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  margin-top: 7rem;
  gap: 4rem;
  color: white;
}

.f-content .map {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.social-icon {
  transform: scale(2);
  border: 2px double white;
  padding: 3px;
  border-radius: 40%;
  transition: all 0.5s;
}
.f-icons {
  display: flex;
  gap: 5rem;
}
.social-icon:hover {
  cursor: pointer;
  transform: scale(3.5);
  border: 0;
  color: black;
  border-radius: 0;
}

@media screen and (max-width: 800px) {
  .f-content {
    margin-top: 5rem;
  }
  .social-icon {
    padding: 5px;
    transform: scale(1.5);
  }
  .f-icons {
    display: flex;
    gap: 4rem;
  }
  .social-icon:hover {
    transform: scale(2);
  }
}
@media screen and (max-width: 680px) {
  .footer {
    margin-top: 0rem;
    width: auto;
  }
  .f-content {
    margin-top: 4rem;
  }
  .social-icon {
    padding: 5px;
    transform: scale(1.2);
  }
  .f-icons {
    display: flex;
    gap: 2rem;
  }
  .social-icon:hover {
    transform: scale(2);
  }
}

@media screen and (max-width: 480px) {
  .f-content {
    margin-top: 3rem;
  }
  .social-icon {
    transform: scale(0.8);
    padding: 5px;
  }
  .f-icons {
    display: flex;
    gap: 1.5rem;
  }
  .social-icon:hover {
    transform: scale(1.5);
  }
}

@media screen and (max-width: 360px) {
  .footer {
    margin-top: 0rem;
  }
  .f-content {
    margin-top: 2rem;
  }
  .social-icon {
    transform: scale(0.8);
    padding: 5px;
  }
  .f-icons {
    display: flex;
    gap: 1.5rem;
  }
  .social-icon:hover {
    transform: scale(1.5);
  }
}
