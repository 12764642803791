.portfolio {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
/* heading */
.portfolio .span1 {
  color: var(--black);
  font-size: 2rem;
  font-weight: bold;
}

.portfolio .span2 {
  color: var(--orange);
  font-size: 1.5rem;
  font-weight: bold;
}
.boxed {
  box-shadow: 0px 20px 14px 3px rgba(0, 0, 0, 0.42);
}
/* slider */

.portfolio .img1 {
  width: 28rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 19px;
  transition: all 0.4s ease-in-out;
}

.portfolio .img2 {
  width: 1rem;
  filter: drop-shadow(-12px 15px 13px rgba(0, 0, 0, 0.25));
  border-radius: 100px;
  background-color: transparent;
}
.portfolio .works2-data .workPara {
  color: var(--gray2);
}
.works2-data {
  position: relative;
  display: flex;
  align-items: flex-start;
  align-self: center;
  transition: all 0.2s ease-in-out;
}
.works2-data:hover {
  cursor: pointer;
}

.portfolio .button2,
.portfolio .button2 {
  --button: linear-gradient(180deg, #fdc50f 16.71%, #fb982f 99.36%);
  --button-hover: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  --button-active: linear-gradient(180deg, #fdc50f 56.71%, #fb982f 69.36%);
  --button-visited: linear-gradient(180deg, #fdc50f 56.71%, #fb982f 69.36%);
  --button-colour: var(--colour-white);
  --button-border: #fb982f;
}
.portfolio .button2 {
  padding: 0.5rem 1rem;
  color: var(--button-white);
  font-weight: 500;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  background: white;
  border: solid 1px var(--button-border);
  box-shadow: inset 0 0 0 2px #fb982f;
  margin-right: 1em;
  border-radius: 100px;
}

.portfolio .button2:hover {
  cursor: pointer;
  background: var(--button-hover);
  color: white !important;
  box-shadow: inset 0 0 0 4px var(--colour-white);
}

.portfolio .button2:active {
  background: var(--button-active);
  color: white !important;
}
.portfolio .boxed2 {
  display: none;
}
.blur2 {
  position: absolute;
  width: 8rem;
  height: 5rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
}
.portfolio .img1:hover {
  cursor: pointer;
  transform: scale(1.1);
}
/* .portfolio .works2 .works2Content{
  display: flex;
  position: absolute;
  top:50%;
  left: -100%;
  top:0;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  width: 150px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1;
  border-color: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  color:beige
}
.portfolio .works2:hover .works2Content{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
} */
.portfolio .techs1 {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-self: center;
}
.works2-data {
  gap: 4rem;
  margin-top: 3rem;
}
.works2-data .cntet {
  margin-left: 0;
  margin-right: 0;
  width: 200px;
}
.portfolio .span2 {
  font-size: 2rem;
}
.portfolio .span1 {
  font-size: 2.4rem;
}
.portfolio .div1 {
  margin-bottom: 2rem;
}
.works2-data .workPara {
  width: 350px;
}
.works2-data .b-github {
  margin-left: 1rem;
}
.load-more {
  transition: all 0.5s;
}
.load-more:hover {
  cursor: pointer;
  transform: scale(1.3);
}

@media screen and (max-width: 800px) {
  .portfolio .div1 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .works2-data .cntet {
    visibility: hidden;
    opacity: 0;
    position: absolute;
  }
  .works2-data {
    position: relative;
    display: block;
    transition: all 0.2s ease-in-out;
  }
  .works2-data::before {
    content: "";
    position: absolute;
    width: 54%;
    height: 100%;
    background: rgba(255, 255, 255, 0.26);
    border-top: 8px solid var(--orange);
    border-bottom: 8px solid var(--orange);
    border-left: 8px solid var(--orange);

    opacity: 0;
    bottom: -10rem;
    right: -10rem;
    overflow: hidden;
    box-shadow: 12px 10px 2px 15px var(--boxShadow);
    border-radius: 0px;
  }
  .works2-data::after {
    content: "";
    position: absolute;
    width: 54%;
    height: 100%;
    border-top: 8px solid var(--orange);
    border-bottom: 8px solid var(--orange);
    border-right: 8px solid var(--orange);

    opacity: 0;
    top: -10rem;
    left: -10rem;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.26);
    box-shadow: 12px 10px 11px 15px var(--boxShadow);
    border-radius: 0px;
  }

  .works2-data:hover:before {
    transition: 0.5s;
    opacity: 1;
    transform: translateY(-15vh) translateX(-35.7vh);
    overflow: hidden;
  }
  .works2-data:hover:after {
    transition: 0.5s;
    opacity: 1;
    transform: translateY(13.3vh) translateX(35.7vh);
    overflow: hidden;
  }

  .works2-data .img1:hover {
    opacity: 0;
    visibility: hidden;
  }
  .works2-data:hover .boxed {
    opacity: 0;
    visibility: hidden;
  }
  .works2-data:hover .cntet {
    transition: 0.5s;
    position: absolute;
    visibility: inherit;
    opacity: 1;
    top: 5px;
    left: 10px;
    height: max-content;
    padding-left: 2rem;
    align-self: center;
    padding-bottom: 1rem;
  }
  .works2-data:hover span:nth-child(1) {
    font-size: 2rem;
    font-weight: 500;
  }
  .works2-data:hover .workPara {
    font-size: 22px;
  }
  .works2-data:hover .b-github {
    margin-top: -5px;
  }
  .works2-data .b-github {
    margin-left: 0;
  }
}

@media screen and (max-width: 680px) {
  .portfolio .span2 {
    font-size: 1.6rem;
  }
  .portfolio .span1 {
    font-size: 2.2rem;
  }
  .portfolio .div1 {
    margin-bottom: 1rem;
  }
  .portfolio .techs1 {
    display: grid;
    grid-template-columns: 85px 90px 110px;
    grid-template-rows: 2rem 2rem;
    grid-column-gap: 1rem;
    grid-template-areas: 2rem;
  }
  .portfolio .div1 {
    align-self: center;
  }
  .works2-data {
    display: block;
    transition: all 0.2s ease-in-out;
    transform: scale(0.9);
    margin-top: 2rem;
    margin-bottom: 3rem !important;
    gap: 0rem;
  }
  .works2-data .img1 {
    width: 25rem;
  }

  .works2-data .cntet {
    opacity: 1;
    visibility: visible;
    position: relative;
    display: flex;
    width: 430px;
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .works2-data .workPara {
    margin-right: 0;

    font-size: 16px;
  }
  .works2-data .worktitle {
    margin-top: 1rem;
    font-size: 25px !important;
  }
  .works2-data .techs {
    margin-right: 2rem;
  }
  .works2-data::before {
    display: none;
  }
  .works2-data::after {
    display: none;
  }
  .works2-data:hover:after {
    display: none;
  }
  .works2-data:hover:before {
    display: none;
  }
  .works2-data .img1:hover {
    visibility: visible;
    opacity: 1;
  }
  .works2-data .boxed {
    display: none;
  }
  .works2-data:hover .boxed {
    opacity: 1;
    visibility: visible;
  }
  .works2-data:hover .cntet {
    opacity: 1;
    visibility: visible;
    position: relative;
    display: flex;
    width: 430px;
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
    top: 0px;
    left: 0px;
    height: max-content;
    padding-left: 0rem;
    align-self: inherit;
    padding-bottom: 0rem;
  }
  .works2-data:hover span:nth-child(1) {
    font-size: 28px !important;
    font-weight: 500;
  }
  .works2-data:hover .workPara {
    font-size: 18px;
  }
  .works2-data .b-github {
    margin-top: 0px;
    margin-left: 0;
  }
}

@media screen and (max-width: 480px) {
  .portfolio .span2 {
    font-size: 1.3rem;
  }
  .portfolio .span1 {
    font-size: 1.7rem;
  }
  .portfolio .div1 {
    margin-bottom: 1rem;
  }
  .portfolio .techs1 {
    display: grid;
    grid-template-columns: 85px 90px 110px;
    grid-template-rows: 2rem 2rem;
    grid-column-gap: 1rem;
    grid-template-areas: 2rem;
  }
  .works2-data {
    display: block;
    transition: all 0.2s ease-in-out;
    margin-top: 1.5rem;
    margin-bottom: 3rem !important;
    gap: 0rem;
  }
  .works2-data .img1 {
    width: 18rem;
  }
  .works2-data .img1-div {
    text-align: center;
  }
  .works2-data .boxed {
    display: none;
  }
  .works2-data .cntet {
    opacity: 1;
    visibility: visible;
    position: relative;
    display: flex;
    width: 350px;
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .works2-data .workPara {
    margin-right: 0;
    width: 300px;

    font-size: 14px;
  }
  .works2-data .worktitle {
    margin-top: 1rem;
    font-size: 21px !important;
  }
  .works2-data .techs {
    margin-right: 2rem;
  }
  .works2-data::before {
    display: none;
  }
  .works2-data::after {
    display: none;
  }
  .works2-data:hover:after {
    display: none;
  }
  .works2-data:hover:before {
    display: none;
  }
  .works2-data .img1:hover {
    visibility: visible;
    opacity: 1;
  }

  .works2-data:hover .boxed {
    opacity: 1;
    visibility: visible;
  }
  .works2-data:hover .cntet {
    opacity: 1;
    visibility: visible;
    position: relative;
    display: flex;
    width: 350px;
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
    top: 0px;
    left: 0px;
    height: max-content;
    padding-left: 0rem;
    align-self: inherit;
    padding-bottom: 0rem;
  }
  .works2-data:hover span:nth-child(1) {
    font-size: 21px !important;
    font-weight: 500;
  }
  .works2-data:hover .workPara {
    font-size: 14px;
  }
  .works2-data .b-github {
    margin-left: 0;
    margin-top: 0px;
  }
}

@media screen and (max-width: 360px) {
  .portfolio .techs1 {
    display: grid;
    grid-template-columns: 85px 90px 110px;
    grid-template-rows: 2rem 2rem;
    grid-column-gap: 1rem;
    grid-template-areas: 2rem;
    margin-left: 10px;
  }
  .works2-data {
    display: block;
    transition: all 0.2s ease-in-out;
    margin-top: 1.5rem;
    margin-bottom: 3rem !important;
    gap: 0rem;
  }
  .works2-data .img1 {
    width: 16rem;
  }
  .works2-data .img1-div {
    text-align: center;
  }
  .works2-data .boxed {
    display: none;
  }
  .works2-data .cntet {
    opacity: 1;
    visibility: visible;
    position: relative;
    display: flex;
    width: 350px;
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .works2-data .workPara {
    margin-right: 0;
    width: 300px;

    font-size: 14px;
  }
  .works2-data .worktitle {
    margin-top: 1rem;
    font-size: 21px !important;
  }
  .works2-data .techs {
    margin-right: 2rem;
  }
  .works2-data::before {
    display: none;
  }
  .works2-data::after {
    display: none;
  }
  .works2-data:hover:after {
    display: none;
  }
  .works2-data:hover:before {
    display: none;
  }
  .works2-data .img1:hover {
    visibility: visible;
    opacity: 1;
  }

  .works2-data:hover .boxed {
    opacity: 1;
    visibility: visible;
  }
  .works2-data:hover .cntet {
    opacity: 1;
    visibility: visible;
    position: relative;
    display: flex;
    width: 350px;
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
    margin-right: 0px !important;
    top: 0px;
    left: 0px;
    height: max-content;
    padding-left: 0rem;
    align-self: inherit;
    padding-bottom: 0rem;
  }
  .works2-data:hover span:nth-child(1) {
    font-size: 21px !important;
    font-weight: 500;
  }
  .works2-data:hover .workPara {
    font-size: 14px;
  }
  .works2-data .b-github {
    margin-left: 0;
    margin-top: 0px;
  }
}
