.Intro {
  display: flex;
  height: 77vh;
  margin-top: 6rem;
}

/* left side styling */
.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}
.i-name {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.i-name > :nth-child(1) {
  color: var(--black);
  font-weight: bold;
  font-size: 3rem;
}
.i-floating-name > span {
  color: #fff;
  font-size: 3em;
  position: absolute;
  transform: translate(0%, 0%);
}
.i-floating-name > :nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 1px var(--orange);
  font-weight: bold;
}
.i-floating-name > :nth-child(2) {
  animation: animate 4s ease-in-out infinite;
  color: var(--yellow);
  font-weight: bold;
}
.i-name > :nth-child(3) {
  font-weight: 100;
  font-size: 14px;
  color: var(--gray2);
  margin-top: 10px;
  margin-top: 4rem;
}
/* .i-button {
  width: 6rem;
  height: 2rem;
} */
.i-icons {
  margin-top: 3rem;
  display: flex;
  gap: 0rem;
}
.i-icons > * {
  transform: scale(0.5);
}
.i-icons > *:hover {
  cursor: pointer;
}
/* right side styling */
.i-right {
  flex: 1;
  position: relative;
}
.i-right > * {
  position: absolute;
  z-index: 1;
}
.i-right > :nth-child(1) {
  transform: scale(0.69);
  left: -15%;
  top: -9%;
}
.i-right > :nth-child(2) {
  top: -4.6rem;
  transform: scale(0.67);
  left: -3rem;
}
.i-right > :nth-child(3) {
  transform: scale(1.4);
  left: 28%;
}
.i-right > :nth-child(4) {
  transform: scale(0.62);
  top: -19%;
  left: -24%;
  border-radius: 50%;
  padding: 0;
}

/* blur */
.blur2 {
  position: absolute;
  width: 8rem;
  height: 5rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
}

.hire-me-content .i-button {
  position: relative;
  font-weight: 800;
  text-transform: uppercase;
  font-size: large;
  width: 7rem;
  height: 2rem;
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
  border-radius: 34px;
  border: 2px solid var(--orange);
  color: white;
  padding: 11px 5px 0px 5px;
  text-align: center;
  align-self: center;
}
.hire-me-content .i-button:hover {
  background: #fff;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.hire-me-content .i-button:before,
.hire-me-content .i-button:after {
  padding: 11px 0px 0px 0px;
  color: white;
  content: attr(data-text);
  position: absolute;
  width: 100%;
  height: 40%;
  background: transparent;
  color: white;
  overflow: hidden;
  top: 0;
  text-align: center;
  text-justify: center;
}
.hire-me-content .i-button:before {
  left: 3px;
  text-shadow: -2px 0 var(--orange);
  animation: glitch-1 2s linear infinite reverse;
}
.hire-me-content .i-button:after {
  left: -3px;
  text-shadow: -2px 0 blue;
  animation: glitch-2 2s linear infinite reverse;
}
.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}

@media screen and (max-width: 800px) {
  .i-right {
    transform: scale(0.72);
    left: -4rem;
    top: -8%;
  }
  .i-floating-name > span {
    color: #fff;
    font-size: 1em;
    position: absolute;
    transform: translate(0%, 0%);
  }
  .floating-div:nth-of-type(1) {
    /* display: none; */
    top: -7rem !important;
  }
  .i-name > :nth-child(1) {
    font-size: 2rem;
  }
  .i-name > :nth-child(2) {
    font-size: 3rem;
  }
  .i-name > :nth-child(3) {
    margin-top: 4rem;
  }
  .Intro {
    display: flex;
    height: 36vh;
    margin-top: 6rem;
  }
}
@media screen and (max-width: 680px) {
  .Intro {
    flex-direction: column;
    gap: 7rem;
    height: 110vh;
    margin-bottom: 0rem;
    margin-top: 0px;
  }
  .i-floating-name > span {
    color: #fff;
    font-size: 1em;
    position: absolute;
    transform: translate(0%, 0%);
  }
  .i-right {
    transform: scale(0.62);
    top: -14%;
  }
  .t-blur1 {
    left: 4rem;
    top: 8rem;
    width: 15rem;
  }
  .floating-div:nth-of-type(1) {
    /* display: none; */
    top: -7rem !important;
  }
  .i-name {
    margin-top: 10px;
  }
  .i-name > :nth-child(1) {
    font-size: 2rem;
  }
  .i-name > :nth-child(2) {
    font-size: 2rem;
  }
  .i-name > :nth-child(3) {
    margin-top: 3rem;
  }
  .i-right {
    margin-top: 4rem;
    margin-left: 70px;
  }
  .i-left {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .i-icons {
    margin-top: 0rem;
  }
  .hire-me-content .i-button {
    font-size: 20px;
    width: 7rem;
    height: 2rem;
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 480px) {
  .Intro {
    flex-direction: column;
    gap: 7rem;
    height: 82vh;
    margin-top: 2rem;
  }

  .i-floating-name > span {
    color: #fff;
    font-size: 1em;
    position: absolute;
    transform: translate(0%, 0%);
  }
  /* .t-blur2 {
  top: 9rem;
  left: 0;
} */

  .i-right {
    transform: scale(0.52);
    top: -8%;
    margin-left: 0px;
    margin-bottom: 1rem;
  }

  .floating-div:nth-of-type(1) {
    /* display: none; */
    top: -7rem !important;
  }
  .i-name > :nth-child(1) {
    font-size: 2rem;
  }
  .i-name > :nth-child(2) {
    font-size: 2rem;
  }
  .i-name > :nth-child(3) {
    margin-top: 3rem;
  }
  .i-icons {
    margin-top: 1rem;
    margin-bottom: -3rem;
  }
  .i-icons > * {
    transform: scale(0.3);
  }
  .hire-me-content .i-button {
    font-size: small;
    width: 5rem;
    height: 1.5rem;
  }
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 55%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
@keyframes glitch-1 {
  0% {
    clip: rect(132px, auto, 101px, 30px);
  }
  5% {
    clip: rect(17px, auto, 94px, 30px);
  }
  10% {
    clip: rect(40px, auto, 66px, 30px);
  }
  15% {
    clip: rect(87px, auto, 82px, 30px);
  }
  20% {
    clip: rect(137px, auto, 61px, 30px);
  }
  25% {
    clip: rect(34px, auto, 14px, 30px);
  }
  30% {
    clip: rect(133px, auto, 74px, 30px);
  }

  40% {
    clip: rect(59px, auto, 130px, 30px);
  }
  45% {
    clip: rect(29px, auto, 84px, 30px);
  }
  50% {
    clip: rect(22px, auto, 67px, 30px);
  }
  55% {
    clip: rect(67px, auto, 62px, 30px);
  }
  60% {
    clip: rect(10px, auto, 105px, 30px);
  }
  65% {
    clip: rect(78px, auto, 115px, 30px);
  }
  70% {
    clip: rect(105px, auto, 13px, 30px);
  }
  75% {
    clip: rect(15px, auto, 75px, 30px);
  }
  80% {
    clip: rect(66px, auto, 39px, 30px);
  }
  85% {
    clip: rect(133px, auto, 73px, 30px);
  }
  90% {
    clip: rect(36px, auto, 128px, 30px);
  }
  95% {
    clip: rect(68px, auto, 103px, 30px);
  }
  100% {
    clip: rect(14px, auto, 100px, 30px);
  }
}
@keyframes glitch-2 {
  0% {
    clip: rect(129px, auto, 36px, 30px);
  }
  5% {
    clip: rect(36px, auto, 4px, 30px);
  }
  10% {
    clip: rect(85px, auto, 66px, 30px);
  }
  15% {
    clip: rect(91px, auto, 91px, 30px);
  }
  20% {
    clip: rect(148px, auto, 138px, 30px);
  }
  25% {
    clip: rect(38px, auto, 122px, 30px);
  }
  30% {
    clip: rect(69px, auto, 54px, 30px);
  }

  40% {
    clip: rect(146px, auto, 34px, 30px);
  }
  45% {
    clip: rect(134px, auto, 43px, 30px);
  }
  50% {
    clip: rect(102px, auto, 80px, 30px);
  }
  55% {
    clip: rect(119px, auto, 44px, 30px);
  }
  60% {
    clip: rect(106px, auto, 99px, 30px);
  }
  65% {
    clip: rect(141px, auto, 74px, 30px);
  }
  70% {
    clip: rect(20px, auto, 78px, 30px);
  }
  75% {
    clip: rect(133px, auto, 79px, 30px);
  }
  80% {
    clip: rect(78px, auto, 52px, 30px);
  }
  85% {
    clip: rect(35px, auto, 39px, 30px);
  }
  90% {
    clip: rect(67px, auto, 70px, 30px);
  }
  95% {
    clip: rect(71px, auto, 103px, 30px);
  }
  100% {
    clip: rect(83px, auto, 40px, 30px);
  }
}
