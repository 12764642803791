.t-wrapper {
  padding: 0 3rem 0 3rem;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  margin-bottom: 4rem;
}
.t-heading {
  align-self: start;
}
.t-heading > * {
  font-size: 2rem;
  font-weight: bold;
}
.t-heading span:nth-of-type(2) {
  color: var(--orange);
}

.tt-blur1 {
  left: 38rem;
  top: 16rem;
}
.tt-blur2 {
  top: 9rem;
  left: 0;
}

.testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
  width: 30rem;
  height: 12rem;
  /* styles same as work.css */
  background: rgba(255, 255, 255, 0.26);
  border: 7px solid var(--blueCard);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
}
.testimonial > img {
  object-fit: cover;
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
}
.testimonial > span {
  color: var(--gray);
}

/* slider */
.t-wrapper .swiper {
  width: 100%;
  height: 70%;
}

.t-wrapper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-wrapper .swiper-pagination-bullet-active {
  background: var(--orange);
}

@media screen and (max-width: 800px) {
  .t-wrapper {
    margin-top: 0;
    gap: 1rem;
    height: 70vh;
    margin-bottom: -4rem;
  }
  .tt-blur1 {
    left: 28rem;
    top: 22rem;
  }
  .tt-blur2 {
    top: 18rem;
    left: 0rem;
  }
  .t-wrapper .swiper-wrapper {
    height: 90%;
    width: 100%;
  }
  .t-wrapper .swiper {
    height: 55%;
    width: 80%;
  }
}

@media screen and (max-width: 680px) {
  .t-wrapper {
    padding: 0;
    height: 80vh;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  .testimonial > img {
    width: 70px;
    height: 70px;
  }
  .tt-blur1 {
    left: 10rem;
    top: 16rem;
    width: 20rem;
  }
  .tt-blur2 {
    top: 9rem;
    left: 0;
    width: 15rem;
  }
  .testimonial {
    width: 20rem;
  }
  .t-wrapper .swiper-wrapper {
    height: 80%;
    width: 100%;
  }
  .t-wrapper .swiper {
    height: 75%;
  }

  .testimonial > span {
    font-size: medium;
  }
}

@media screen and (max-width: 480px) {
  .t-wrapper {
    padding: 0;
    height: 60vh;
    margin-top: 3rem;
    margin-bottom: 0;
  }
  .testimonial > img {
    width: 70px;
    height: 70px;
  }
  .t-heading > * {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .tt-blur1 {
    left: 8rem;
    top: 16rem;
    width: 15rem;
  }
  .tt-blur2 {
    top: 9rem;
    left: 0;
    width: 10rem;
  }

  .t-wrapper .swiper-wrapper {
    height: 85%;
    width: 100%;
  }
  .t-wrapper .swiper {
    height: 75%;
    width: 85%;
  }

  .testimonial > span {
    font-size: medium;
  }
}

@media screen and (max-width: 360px) {
  .t-wrapper {
    height: 75vh;
  }
  .testimonial > span {
    font-size: small;
    margin-top: 10px;
  }
}
