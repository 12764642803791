.works {
  padding: 0 3rem 0 3rem;
  display: flex;
  height: 90vh;
  margin-top: 4rem;

  /* scroll */
  align-items: center;
}
/* left side */
.works .awesome {
  display: flex;
  flex-direction: column;
  position: relative;
}

.works .awesome > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.works .awesome > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.works .awesome > :nth-child(3) {
  color: var(--gray);
  font-size: 14px;
  margin-top: 1rem;
}
.works .awesome .aws-info {
  line-height: 2;
}
.works .awesome .s-button {
  width: 8rem;
  height: 3rem;
  margin-top: 4rem;
}

/* right side */
.works .w-right {
  position: relative;
}

.w-mainCircle {
  left: 9rem;
  position: relative;
  width: 18rem;
  height: 18rem;
  border-radius: 100%;
  box-shadow: var(--smboxShadow);
  top: 2rem;
  background: white;
  /* darkMode */
  z-index: 2;
}
.w-mainCircle > * {
  position: absolute;
}
.w-mainCircle > :nth-child(1) {
  top: -3rem;
  left: 6rem;
}
.w-mainCircle > :nth-child(2) {
  left: -3rem;
  top: 5rem;
}
.w-mainCircle > :nth-child(3) {
  left: 6rem;
  top: 5rem;
}
.w-mainCircle > :nth-child(4) {
  left: 15rem;
  top: 5rem;
}
.w-mainCircle > :nth-child(5) {
  left: 6rem;
  top: 13rem;
}
.w-secCircle {
  width: 6rem;
  height: 6rem;
  position: absolute;
  border-radius: 100%;
  border: 5px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  background: "none";
  box-shadow: var(--smboxShadow);
  background: white;
}

.w-secCircle > img {
  transform: scale(0.6);
}

.w-backCircle {
  position: absolute;
  height: 13rem;
  width: 13rem;
  border-radius: 100%;
  /* darkMode */
  z-index: 1;
}
.blueCircle {
  background: #1949b8;
  left: 18rem;
  top: 0rem;
}
.yellowCircle {
  background: #f5c32c;
  left: 18rem;
  top: 8rem;
}

.s-blur2 {
  top: -12rem;
  right: 18rem;
}
@media screen and (max-width: 800px) {
  .works {
    display: flex;
    height: 24rem;
    padding: 0;

    justify-content: space-around;
    align-items: inherit;
  }
  .works .w-right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transform: scale(0.8);
    margin-top: -2rem;
    margin-right: 8rem;
  }

  .works .w-backCircle {
    left: 8rem;
  }
  .works .w-left .awesome {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .works .awesome > :nth-child(1) {
    color: var(--black);
    font-size: 2rem;
    font-weight: bold;
  }
  .works .awesome > :nth-child(2) {
    color: var(--orange);
    font-size: 2rem;
    font-weight: bold;
  }
  .works .awesome > :nth-child(3) {
    color: var(--gray);
    font-size: 12px;
    margin-top: 1rem;
  }
  .works .awesome .s-button {
    width: 8rem;
    height: 2.5rem;
    margin-top: 2rem;
  }
  .works .awesome .aws-info {
    line-height: 2;
  }
}

@media screen and (max-width: 680px) {
  .works {
    flex-direction: row;
    margin-top: 8rem;
    height: 20rem;
    padding: 0;
    gap: 0rem;
  }
  .works .w-left {
    width: 300px;
  }
  .works .w-left .awesome {
    align-items: flex-start;
    margin-left: 0rem;
    margin-left: 3.5rem;
  }
  .works .w-left .awesome .aws-info {
    width: 260px;
    color: var(--gray2) !important;
    text-align: left;
    margin-top: 2rem;
    font-size: 12px;
    line-height: 1.5;
  }
  .works .w-right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transform: scale(0.6);
    margin-top: -2rem;
    margin-right: 0rem;
  }
  .works .w-mainCircle {
    margin-right: 2rem;
    left: 0rem;
  }
  .works .w-backCircle {
    right: 8rem;
    left: 0;
  }

  .works .awesome > :nth-child(1) {
    color: var(--black);
    font-size: 1.2rem;
    font-weight: bold;
  }
  .works .awesome > :nth-child(2) {
    color: var(--orange);
    font-size: 1.2rem;
    font-weight: bold;
  }
  .works .awesome .s-button {
    width: 7rem;
    height: 2.5rem;
    margin-top: 2rem;
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .works {
    flex-direction: column;
    height: 32rem;
    padding: 0;
    gap: 4rem;
    margin-bottom: 5rem;
  }
  .works .w-left {
    width: 25rem;
    margin-top: 2rem;
  }
  .works .w-left .awesome {
    align-items: center;
    margin-left: 0rem;
  }
  .w-left .awesome .aws-info {
    width: 350px;
    margin-left: 0rem;
    color: var(--gray2) !important;
    text-align: left;
    margin-top: 2rem;
    font-size: 16px;
  }
  .works .w-right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transform: scale(0.7);
    margin-right: -10rem;
  }
  .w-mainCircle {
    position: static;
  }
  /* .w-backCircle {
    left: 8rem !important;
    top: -2rem;
  } */
  .works .awesome > :nth-child(1) {
    color: var(--black);
    font-size: 2.2rem;
    font-weight: bold;
  }
  .works .awesome > :nth-child(2) {
    color: var(--orange);
    font-size: 1.8rem;
    font-weight: bold;
  }

  .works .awesome .s-button {
    width: 7rem;
    height: 2.3rem;
    margin-top: 2rem;
  }
  .works .awesome .aws-info {
    line-height: 1;
  }
}

@media screen and (max-width: 360px) {
  /* .works {
    flex-direction: column;
    height: 32rem;
    padding: 0;
    gap: 4rem;
    margin-bottom: 5rem;
  } */
  .works .w-left {
    width: 19rem;
    margin-top: 2rem;
  }
  .works .w-left .awesome {
    align-items: center;
    margin-left: 0rem;
  }
  .w-left .awesome .aws-info {
    width: 350px;
    margin-left: 0rem;
    color: var(--gray2) !important;
    text-align: left;
    margin-top: 2rem;
    font-size: 16px;
  }
  .works .w-right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transform: scale(0.7);
    margin-right: -1rem;
  }
  .w-mainCircle {
    position: static;
  }
  /* .w-backCircle {
    left: 8rem !important;
    top: -2rem;
  } */
  .works .awesome > :nth-child(1) {
    color: var(--black);
    font-size: 2.1rem;
    font-weight: bold;
  }
  .works .awesome > :nth-child(2) {
    color: var(--orange);
    font-size: 1.7rem;
    font-weight: bold;
  }

  .works .awesome .s-button {
    width: 7rem;
    height: 2.3rem;
    margin-top: 2rem;
  }
  .works .awesome .aws-info {
    line-height: 1;
  }
}
