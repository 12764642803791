.services {
  padding: 0rem;
  display: flex;
  height: 30rem;
  margin-bottom: 8rem;
  margin-top: 9rem;
  z-index: 1;
}
/* left side */
.services .awesome {
  display: flex;
  flex-direction: column;
  position: relative;
  /* gap: 20px; */
}

.services .awesome > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: bold;
}
.services .awesome > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
}
.my-services {
  color: var(--gray2);
  font-size: 14px;
  margin-top: 1rem;
  width: 120vh;
  line-height: 2;
}
.s-button {
  height: 2.6rem;
  margin-top: 2rem;
}
/* right sided */
.cards {
  position: relative;
}
.cards > * {
  position: absolute;
}

/* blur */
.s-s-blur2 {
  left: 4rem;
  top: 28rem;
  background: rgb(221, 223, 253);
}

.s-blur1 {
  top: 13rem;
  left: -18rem;
}

@media screen and (max-width: 800px) {
  .services {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 42rem;
    padding: 0;
    margin-top: 3rem;
  }

  .cards {
    transform: scale(0.9);
    margin-bottom: 0rem;
    margin-left: 12rem;
  }
  .services .awesome {
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
  }
  .my-services {
    text-align: center;
  }
}

@media screen and (max-width: 680px) {
  .services {
    flex-direction: column;
    gap: 5rem;
    height: 68rem;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 34vh;
    margin-top: 0rem;
    margin-bottom: 2rem;
    gap: 18rem;
    margin-left: 0rem;
    transform: scale(1.1);
  }
  .cards > * {
    position: static;
  }
  .services .awesome {
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
  }
  .my-services {
    text-align: center;
    line-height: 1.5;
  }
}
@media screen and (max-width: 480px) {
  .services {
    flex-direction: column;
    gap: 2rem;
    height: 60rem;
    padding: 0;
    margin-top: 3rem;
  }

  .my-services {
    width: 40vh;
    line-height: 1;
    text-align: left;
  }
  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 14vh;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 18rem;
    margin-left: 0rem;
    transform: scale(1.1);
  }
  .cards > * {
    position: static;
  }
  .services .awesome {
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 360px) {
  .services {
    flex-direction: column;
    gap: 2rem;
    height: 60rem;
    padding: 0;
    margin-top: 16rem;
  }

  /* .my-services {
    width: 40vh;
    line-height: 1;
    text-align: left;
  } */
  .cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 6vh;
    margin-top: 2rem;
    margin-bottom: 2rem;
    gap: 18rem;
    margin-left: 0rem;
    transform: scale(1.1);
  }
  /* .cards > * {
    position: static;
  }
  .services .awesome {
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
  }  */
}
